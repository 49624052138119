import React from "react";
import "./style.css";

function ProfileCard({
  colorLabel,
  id,
  isVisible,
  handleProfileDetails,
  isMobile,
  ...props
}) {
  return (
    <div
      key={id}
      className={`panel card mb-3 profile-card wit-profile-${colorLabel} ${
        isVisible ? "" : "profile-collapsed"
      }`}
      data-color="#FFF7DE"
      onClick={() => handleProfileDetails(id)}
    >
      {isVisible && isMobile ? (
        <div
          className={`mobile-profile-view ${isVisible ? "show" : "hide"}`}
          id="mobile-profile"
        >
          <div className="profile-modal" id="profile-modal">
            <Card {...props} isVisible={isVisible} />
          </div>
        </div>
      ) : (
        <Card {...props} isVisible={isVisible} />
      )}
    </div>
  );
}
export default ProfileCard;

const Card = ({ color, img, name, about, role, isVisible }) => (
  <>
    <div className="d-md-flex">
      <div className="wit-profile-cont">
        <div
          className={`profile-img ${isVisible ? "active-profile" : ""}`}
          style={{ backgroundColor: color }}
        >
          <img src={img} className="img-fluid rounded-start" alt={name} />
        </div>
        <div className="wit-name-wrap d-flex justify-content-between align-items-end">
          <p className="mb-0 wit-profile-name">
            <b className="one-word">{name}</b>
          </p>
          <b className="plus-icon">+</b>
        </div>
      </div>
      <div className="wit-profile-desc">
        <div className="card-body">
          {about.map((about, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: about }} />
          ))}
        </div>
      </div>
    </div>
    <p className="one-word wit-position">{role}</p>
  </>
);
