import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Wit from "./Pages/Wit";
import About from "./Pages/Wit/components/About";
import Requirement from "./Pages/Wit/components/Requirement";
import "./App.css";

const defaultTag = [
  {
    name: "Women in tech",
    content:
      "The Moniepoint Women in Tech Internship is aimed at ensuring that young women have access to building a career in technology.",
  },
  { property: "og:title", content: "Moniepoint - WIT" },
];

const helmetContext = {};
function App() {
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Helmet titleTemplate="Moniepoint WIT - %s">
          {defaultTag.map((tag, index) => <meta key={index} {...tag} />)}
        </Helmet>
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/requirement" element={<Requirement />} />
            <Route path="/wit" exact={false} element={<Wit />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
