import tefeImg from "../assets/img/Tefe.png";
import bintaImg from "../assets/img/Binta.png";
import barakatImg from "../assets/img/Barakat.png";
import dorcasImg from "../assets/img/Dorcas.png";
import pearlImg from "../assets/img/Pearl.png";
import jumaiImg from "../assets/img/Jumai.png";
import danielaImg from "../assets/img/Daniela.png";
import afolabiImg from "../assets/img/Afolabi.png";
import joelImg from "../assets/img/Joel.png";
import preciousImg from "../assets/img/Precious.png";
import chiomaImg from "../assets/img/Chioma.png";
import damilolaImg from "../assets/img/Damilola.png";
import { colors } from "../constants";

export const years = ["2021", "2022", "2023"];

export const data = {
  2021: [
    {
      name: "Barakat Ajadi",
      role: "Associate Product Manager",
      img: barakatImg,
      color: colors.lavender,
      about: [
        `
        Barakat always knew she wanted a path in computer science and technology since her father got her a computer when she was very young. 
        She is excited about community building and the opportunity it presents to help others grow. 
          `,
        `
        At work, she’s passionate about ensuring reliability to make users happy, and her favourite things are anime, ice cream and travelling, in no particular order.
            .
          `,
      ],
    },
    {
      name: "Tefe Oladejo",
      role: "Frontend Engineer",
      img: tefeImg,
      color: colors["lite-lemon"],
      about: [
        `
            Tefe enjoys travelling and taking pictures, and she is passionate
            about leveraging front-end development to allow people limitless
            access to a good life.
          `,
        `
            She had a pretty sweet transition into tech and wrote all about it 
            <a href="https://moniepoint.com/blog/the-right-shoulder-a-story-of-beginnings-and-progress">
              here
            </a>
            .
          `,
      ],
    },
    {
      name: "Binta Umar",
      role: "Backend Engineer",
      img: bintaImg,
      color: colors.turquoise,
      about: [
        `
        Binta enjoys simple and effective problem-solving. She is fascinated by how technology affects people's lives and has a knack for innovation.
          `,
        `
        She enjoys learning about healthy lifestyles, going on hikes, and seeing how her career in financial technology helps those around her with their businesses.

            .
          `,
      ],
    },
  ],
  2022: [
    {
      name: "Chioma Ibekwe",
      role: "Product Designer",
      img: chiomaImg,
      color: colors.mint,
      about: [
        `Chioma loves crafting beautiful experiences through design.
          `,
        `She had a career in Medicine & Surgery before switching to design, and she’s thrilled by the transition. She loves junk food and shows that are strange, dark and mysterious.
          `,
      ],
    },
    {
      name: "Feranmi Joel",
      role: "Technical Product Manager",
      img: joelImg,
      color: colors.turquoise,
      about: [
        `Feranmi leverages her technology and product management expertise to develop and launch successful products with a meaningful impact on people's daily lives.
          `,
        `
        She has a positive energy that inspires those around her and enjoys reading tweets, newsletters, and books (except autobiographies) outside of work. She’s also known for her quirky humour and love of classic films.
          `,
      ],
    },
    {
      name: "Damilola Omolori",
      role: "Backend Engineer",
      img: damilolaImg,
      color: colors["lite-lemon"],
      about: [
        `
        Damilola loves travelling and is always on the lookout for new dishes to try.
          `,
        `She wants to provide top-notch, innovative and user-friendly software that enhances people’s lives and addresses real-world issues. She also hopes that her work will help advance the field of software engineering.
          `,
      ],
    },
    {
      name: "Precious Uzochukwu",
      role: "Backend Engineer",
      img: preciousImg,
      color: colors["lite-peach"],
      about: [
        `
        Precious’ ultimate goal is to own a foundation that reaches out to families who have children born with hearing defects and teach them sign language to encourage communication between them.
          `,
        `
        She graduated with an honours degree in computer science, and when she’s not doing cool stuff with code, she loves to read books and play video games.
          `,
      ],
    },
  ],
  2023: [
    {
      name: "Pearl  Akpan",
      role: "Frontend Engineer",
      img: pearlImg,
      color: colors.mint,
      about: [
        `Pearl wanted to be a pilot at age 5 but changed course when she learnt that planes could crash. Today, she takes people on it's journeys through frontend engineering at Moniepoint. `,
        `
        Although Pearl doesn’t have a favourite meal, she eats all kinds of pasta. 
          `,
      ],
    },
    {
      name: "Dorcas Abang",
      role: "Technical Product Manager",
      img: dorcasImg,
      color: colors["lite-peach"],
      about: [
        `
        When she’s not enjoying her favourite meal of Afang soup paired with Northern Tuwo, Dorcas is passionate about using technology to create positive change.
          `,
        `She also loves to stay updated on tech trends through books and watching movies.
          `,
      ],
    },
    {
      name: "Daniela Uzosike",
      role: "Systems Administrator",
      img: danielaImg,
      color: colors["lite-lemon"],
      about: [
        `Daniela Uzosike is passionate about fixing things. She ties this passion to her love for repairing her toys and play tools as a child.
          `,
        `This early interest led her to pursue a degree in Engineering, in order to solve real-world problems. Outside of work, Daniela loves good food and music. 
          `,
      ],
    },
    {
      name: "Jumai  Tijani",
      role: "QA Engineer",
      img: jumaiImg,
      color: colors.lavender,
      about: [
        `Jumai loves to try out DIY projects that she finds interesting and is passionate about leveraging technology to improve life.
          `,
        `Her first shot at software testing was at a boot camp, and she’s been on the path since. In her free time, she reads books and newsletters.
          `,
      ],
    },
    {
      name: "Afolabi Dolapomade",
      role: "QA Engineer",
      img: afolabiImg,
      color: colors["lite-almond"],
      about: [
        `When she’s not ensuring the highest level of quality in software products, Afolabi is a pancake-flipping maestro.
          `,
        `As a kid, she dreamt of being a professional superhero, but now she explores her superpowers by writing bug-free software and saving the day, one line of code at a time.
          `,
      ],
    },
  ],
};
