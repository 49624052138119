import React, { useRef } from "react";
import "./style.css";
import RequirementCard from "../../../../common/component/RequirementCard";
import useHorizontalScroll from "../../../../common/hooks/useHorizontalScroll";
import data from "../../../../common/data/requirements";

function Requirement() {
  const componentRef = useRef(null);
  const sliderRef = useRef(null);
  useHorizontalScroll(".wit-req-card", componentRef, sliderRef);


  return (
    <section id="requirements">
      <div>
        <div className="day">
          <div className="wit-requirement-hero wit-header-wrap" id="wit-req-text">
            <span className="wit-requirement-heading h1">
              {" "}
              A day in the life of an intern
            </span>
            <sub className="wit-small-text">
              What you will be responsible for as a Women- in-Tech intern.
            </sub>
          </div>
        </div>
        <div
          className="page-section req-outer"
          style={{ overflow: "hidden" }}
          ref={componentRef}
        >
          <div className="req-slide" style={{ margin: "auto", width: "100%" }}>
            <div
              ref={sliderRef}
              className="wit-requirements wit-scroller about-scroll-wrap pt-5"
              id="horizontalScrollingReq"
            >
              {
                data.map((requirement, id)=> (
                  <RequirementCard key={id} {...requirement}/>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Requirement;
