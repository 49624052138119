import React from "react";
import "./style.css";
import Description from "./Description";

function About() {
  return (
    <div id="about">
      <Intro/>
      <Description/>
    </div>
  );
}

export default About;

const Intro = () => (
  <>
    <section
      className="wit-section-container"
    >

      <div className="h-100 d-flex wit-about-div about-intro">
        <div className="page-section animate__animated animate__fadeInUp">
          <div
            className="wit-about text-center about-descr d-flex"
            id="about-head"
          >
            <p>
              The Moniepoint Women in Tech Internship is aimed at ensuring that
              young women have access to building a career in technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);
