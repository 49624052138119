import { useState, useEffect, useCallback } from "react";
import WitApplyModal from "../../../common/component/WitApplyModal";
import { useNavigate } from "react-router-dom";
import chevronDown from "../../../common/assets/img/chevron-down.svg";
import BackgroundImage from "../../../common/assets/img/Girl.png";
import MbBackgroundImage from "../../../common/assets/img/mobileGirl.png";
import TimeCard from "../../../common/component/TimeCard";
import HomeNav from "../../../common/component/HomeNav";
import { navList } from "../../../common/component/Header/mockdata";
import "./style.css";

function HomeComponent() {
  const navigate = useNavigate();
  const [zoomIn, setZoom] = useState(false);
  const [isVisible, setVisible] = useState(false);

  const countDownMock = [
    { title: "days", value: "00" },
    { title: "hours", value: "00" },
    { title: "minutes", value: "00" },
  ];

  const handleEffect = useCallback(
    (nav = "about") => {
      setZoom(true);
      setTimeout(() => {
        navigate(`/wit#${nav}`, { state: { nav } });
      }, 500);
    },
    [navigate]
  );

  const handleModal = () => {
    // setVisible(!isVisible);
    setVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollY + clientHeight >= scrollHeight && !isVisible) {
        handleEffect();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleEffect, isVisible]);

  useEffect(() => {
    const countDownDate = new Date("Mar 17, 2024 23:59:59").getTime();
    const daysElem = document.getElementById("days");
    const hoursElem = document.getElementById("hours");
    const minsElem = document.getElementById("minutes");
    function countDown() {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const x = setInterval(countDown, 60000);

      if (distance < 0) {
        clearInterval(x);
        daysElem.innerHTML = "00";
        hoursElem.innerHTML = "00";
        minsElem.innerHTML = "00";
      } else {
        daysElem.innerHTML = days;
        hoursElem.innerHTML = hours;
        minsElem.innerHTML = minutes;
      }
    }
    if (daysElem) {
      countDown();
    }
  });

  return (
    <div>
      <div
        className={`background-container animate__animated animate__fadeInDown delay-2 for-desktop ${
          zoomIn ? "zoomed" : ""
        }`}
      >
        <img
          className="background-image"
          src={BackgroundImage}
          alt="background"
        />
      </div>
      <div
        className={`background-container animate__animated animate__fadeInDown delay-2 for-mobile ${
          zoomIn ? "zoomed" : ""
        }`}
      >
        <img
          className="background-image"
          src={MbBackgroundImage}
          alt="background"
        />
      </div>
      <div className={`${zoomIn ? "zoomed" : ""} home-wrap`}>
        <div className="wit-wrap h-100 wit-container" id="wit-page">
          <div className="home-content" id="page-wrap">
            <div className="home-nav-wrapper">
              <HomeNav />
            </div>
            {/* hero banner */}
            <section className="section-wrap hero-section" id="home-scroll">
              <div className="abt-wrapper">
                <div className="d-md-flex home-hero justify-content-center text-white">
                  <div className="text-group d-flex align-items-center animate__animated animate__fadeInLeft delay-3">
                    <div>
                      <span className="search">The search for the</span>
                      <p className="dream d-flex align-items-center">DREAM </p>
                      <span className="is-on">is on</span>
                    </div>
                    <div>
                      <span className="wit-5 pl-2">10</span>
                    </div>
                  </div>

                  <div className="d-flex time-counter align-items-center animate__animated animate__fadeInRight delay-3">
                    {countDownMock.map(({ title, value}, index ) => (
                      <div key={index}>
                        <TimeCard title={title} value={value} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-center mt-1 animate__animated animate__fadeInUp delay-3 apply-now">
                  <button className="btn btn-wit-apply" onClick={handleModal}>
                    {/* <b> APPLY NOW</b> */}
                    <b> APPLICATION CLOSED</b>
                  </button>
                  <span className="my-4 scroll-down d-flex align-items-center justify-content-center">
                    <span
                      className="wit-scroll home-link"
                      onClick={() => handleEffect()}
                      data-url="./wit.html"
                    >
                      <img
                        className="wit-bounce"
                        src={chevronDown}
                        alt="chevron-down"
                      />
                    </span>
                  </span>
                </div>
              </div>
            </section>
          </div>
          <div className="wit-home-nav animate__animated animate__fadeInUp delay-2">
            <nav className="d-flex justify-content-between w-100">
              <ul className="d-flex">
                {navList.slice(0, 2).map((nav, index) => (
                  <li key={index}>
                    <span
                      onClick={() => handleEffect(nav)}
                      className="text-dark home-link"
                    >
                      {nav}
                    </span>
                  </li>
                ))}
              </ul>
              <ul className="d-flex">
                {navList.slice(-2).map((nav, index) => (
                  <li key={index}>
                    <span
                      onClick={() => handleEffect(nav)}
                      className="text-dark home-link"
                    >
                      {nav}
                    </span>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <WitApplyModal visible={isVisible} handleClose={handleModal} />
        </div>
      </div>
    </div>
  );
}

export default HomeComponent;
