import React from "react";
import logo from "../../assets/img/moniepoint-logo.svg";

function HomeNav({ variant }) {
  return (
    <header>
      <nav>
        <ul className="d-flex justify-content-between align-items-center">
          <li>
            <a
              href="/"
            >
              <img src={logo} alt="logo" />
            </a>
          </li>
          <li>
            <a
              href="https://moniepoint.com/inside-moniepoint"
              target="_blank"
              rel="noreferrer"
              style={{ color: variant === "dark" ? "black" : "white" }}
              className="text-uppercase text-decoration-underline"
            >
              Inside Moniepoint
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default HomeNav;
