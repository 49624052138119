import uxImg from "../assets/img/roles-ux.svg";
import dataEngineerImg from "../assets/img/roles-de.svg";
import sysAdminImg from "../assets/img/roles-sys-admin.svg";
import tpmImg from "../assets/img/roles-tpm.svg";
import beImg from "../assets/img/roles-be.svg";
import ceImg from "../assets/img/roles-ce.svg";
import techSuptImg from "../assets/img/roles-tech-supt.svg";
import feImg from "../assets/img/roles-fe.svg";

const roles = [
  {
    title: "Systems Administrator",
    image: sysAdminImg,
  },
  {
    title: "Backend Engineer",
    image: beImg,
  },
  {
    title: "Data Engineer",
    image: dataEngineerImg,
  },
  {
    title: "Technical Product Manager",
    image: tpmImg,
  },
  {
    title: "Cloud Engineer",
    image: ceImg,
  },
  {
    title: "UI/UX",
    image: uxImg,
  },
  {
    title: "Frontend Engineer",
    image: feImg,
  },
  {
    title: "Technical Support",
    image: techSuptImg,
  },
];
export default roles;