import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useObserver from "../../hooks/useObserver";
import { colors } from "../../constants";
import { useLocation } from "react-router-dom";
import { MOBILE_WIDTH_THRESHOLD } from "../../constants";

const PageWrapper = ({ children, load }) => {
  const sectionInView = useObserver();
  const [isLoading, setIsLoading] = useState(true);
  const [loadCount, setLoadCount] = useState(0);

  const location = useLocation();
  
  const canRedirect =
    loadCount <= 1 &&
    location.hash &&
    window.matchMedia &&
    window.matchMedia(
      `only screen and (min-width: ${MOBILE_WIDTH_THRESHOLD}px)`
    ).matches;

  if (canRedirect) {
    const path = location.hash.split("#")[1];
    const target = document.getElementById(path);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
      setLoadCount(loadCount + 1);
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const navElem = document.getElementById("wit-nav");
    if (navElem) {
      if (sectionInView === "success-stories") {
        navElem.style.background = colors["lite-lemon"];
      } else {
        navElem.style.background = "white";
      }
    }
  }, [sectionInView]);
  return (
    <>
      <Helmet title={sectionInView || "Home"} />
      {load && isLoading ? (
        <div
          className=" d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-grow" role="status"></div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default PageWrapper;
