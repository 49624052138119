import React from "react";
import "./style.css"

function TimeCard({title, value}) {
  return (
    <div className="card time-card">
      <p className="time-value" id={title}>
        {value}
      </p>
      <p className="title">{title}</p>
    </div>
  );
}

export default TimeCard;
