import { useEffect, useState, useLayoutEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { MOBILE_WIDTH_THRESHOLD } from "../constants";

const useHorizontalScroll = (panelClassName, componentRef, sliderRef) => {
  const [amountToScroll, setAmountToScroll] = useState(0);
  const [allPanels, setPanels] = useState([]);
  const [isMobileDevice, setMobileDevice] = useState(false);

  useEffect(() => {
    function handleResize() {
      let x = 0;

      if (allPanels.length) {
        x =
          allPanels[0].offsetWidth * allPanels.length + 250 - window.innerWidth;
      }
      setAmountToScroll(x);
      setMobileDevice(window.innerWidth < MOBILE_WIDTH_THRESHOLD);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [allPanels]);

  useEffect(() => {
    if (isMobileDevice) {
      ScrollTrigger.killAll();
    } else {
      ScrollTrigger.refresh();
    }
  }, [isMobileDevice]);

  useLayoutEffect(() => {
    if (
      !componentRef.current ||
      !panelClassName ||
      !sliderRef.current ||
      isMobileDevice
    ) {
      return;
    }
    if (
      window.matchMedia &&
      window.matchMedia(
        `only screen and (min-width: ${MOBILE_WIDTH_THRESHOLD}px)`
      ).matches
    ) {
      const config = {
        ".gsap-about": { top: -5, right: 800 },
        ".wit-req-card": { top: 0, right: 350 },
        ".profile-card": { top: -90, right: 250 },
      };
      gsap.registerPlugin(ScrollTrigger);

      let ctx = gsap.context(() => {
        let panels = gsap.utils.toArray(panelClassName);
        setPanels(panels);
        let x =
          panels[0].offsetWidth * panels.length +
          (config[panelClassName]?.right || 0) -
          window.innerWidth;
        setAmountToScroll(x);
        if (!isMobileDevice) {
          gsap.to(panels, {
            x: -amountToScroll,
            ease: "none",
            scrollTrigger: {
              trigger: sliderRef.current,
              pin: true,
              start: `${config[panelClassName].top}% top`,
              scrub: 1,
              snap: 1 / (panels.length - 1),
              end: () => "+=" + (sliderRef.current.offsetWidth + 550),
            },
          });
          ScrollTrigger.refresh();
        }
      }, componentRef);
      return () => ctx.revert();
    }
  }, [amountToScroll, sliderRef, panelClassName, componentRef, isMobileDevice]);

  return { componentRef, sliderRef, ScrollTrigger };
};

export default useHorizontalScroll;
