const faqs = [
  {
    title: "What is Moniepoint?",
    description: `<p> Moniepoint is an all-in-one financial services platform for emerging markets. In simple terms, if you need to pay for anything in Nigeria, odds are you're going to pay at a Moniepoint terminal, to a Moniepoint business account, from a Moniepoint personal account. Pretty sweet, right?</p>
    <p>Since 2019, Moniepoint’s technology has powered over 3 million people, offering personal and business banking, payment, credit and business management tools to help them succeed. In 2023 alone, we processed $182 billion and we currently processes the majority of the POS transactions in Nigeria. Okay, we'll stop tooting our horn now.</p>
  `,
  },
  {
    title: "How long is the internship?",
    description: `<p> The internship is 6 months long or 6 months short.
    Depending on how you see the glass.</p>
  `,
  },
  {
    title: "Can I get retained after the internship?",
    description: `<p>
    Absolutely. Think of the internship as an exclusive
    women-only pass to join the team. You’ll be assessed at
    the end of the internship and offered a full-time position
    based on your performance.
  </p>
  `,
  },
  {
    title: "What is the recruitment process?",
    description: `<p>The recruitment process involves;</p>
    <ul>
      <li className="d-flex align-items-start align-items-center">
        <p>- General and psychometric assessment.</p>
      </li>
      <li className="d-flex align-items-start">
        <p>- Technical assessment.</p>
      </li>
      <li className="d-flex align-items-start">
        <p>
          - A behavioural and technical interview with the
          hiring manager.
        </p>
      </li>
      <li className="d-flex align-items-start">
        <span>
          All interview stages are Virtual. We know this
          probably sounds like a lot, but we promise our
          recruiters don’t bite. You’ve got this!
        </span>
      </li>
    </ul>
  `,
  },
  {
    title: "I don’t think I have enough experience. Will I get in?",
    description: ` <p>
    You’ll never know until you try. What we can assure you of
    is that we’ll give every application a fair review.
  </p>
  `,
  },
  {
    title: "Is it a paid internship?",
    description: `   <p>
    How awkward would it be if we said No? But yes. You’ll be
    paid a competitive salary. We know it has a bad rep, but
    we mean “competitive” in a good way *wink
  </p>
  `,
  },
  {
    title: "Is the internship open to only women?",
    description: ` <p>
    Thankfully, yes. Sorry guys, but you’ll have to sit this
    one out. Although, we have other opportunities that are
    open to men as well.
  </p>
  `,
  },
  {
    title: "Why is the internship open to women only?",
    description: `  <p>
    Women have always been central to the growth and evolution of technology. 
    Yet, recent statistics show that women remain underrepresented in the technology industry. 
    Bummer isn't it?
  </p>
  
  <p>
This International Women's Day, themed 
"Invest in Women: Accelerate Progress," emphasises the urgent need to support and empower women in technology. 
Women should be encouraged to pursue paths in building technology solutions, and their innovations should be celebrated and supported.
That's what we're doing with the Moniepoint Women in Tech Internship.
  </p>
  `,
  },
  {
    title: "Does Moniepoint have other internship programs?",
    description: `<p>
    Sure. We accept internships on a rolling basis through our
    partnerships with universities to train young people, or
    through direct applications. Don’t be afraid to shoot your
    shot.
  </p>
  `,
  },
];

export default faqs;
