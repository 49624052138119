import React from "react";
import Header from "../../common/component/Header";
import About from "./components/About";
import Faq from "./components/Faq";
import Alumni from "./components/Alumini";
import Requirement from "./components/Requirement";
import Footer from "../../common/component/Footer";
import PageWrapper from "../../common/component/PageWrapper";

function Wit() {

  return (
    <PageWrapper>
      <div className="wit-container">
        <Header />
      </div>
      <main className="about-main">
          <About />
          <Requirement />
          <Alumni />
          <Faq />
      </main>
      <Footer />
    </PageWrapper>
  );
}

export default Wit;
