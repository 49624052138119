import React, { useState } from "react";
import { years } from "../../../../../common/data/alumunis";
import ProfileList from "../ProfileList";
import "./style.css"

function MobileProfileList({ activeYear, handleActiveYear }) {
  const [showAll, setShow] = useState(false);

  const handleShowAll = (year) => {
    if(year === activeYear){
      setShow(!showAll);
    } else{
      handleActiveYear(year)
    }
  };

  return (
    <div>
      {years.map((year, idx) => (
        <div
          className={`alum-year-wrap ${
            showAll && year === activeYear ? "show-all" : ""
          }`}
          key={idx}
        >
          <div className="alum-header">
            {showAll && activeYear === year ? (
              <div>
               <div className="mb-4">
               <span className="alum-back alum-link" onClick={() => handleShowAll(year)}>
                  BACK
                </span>
               </div>
                <span className="mb-alum-title">{`${year} Interns`}</span>
              </div>
            ) : (
              <div
                onClick={() => handleActiveYear(year)}
                className="d-flex align-items-center justify-content-between"
              >
                <div className="mb-alumini-year">{year}</div>
                <span className="alum-link" onClick={() => handleShowAll(year) }>SEE ALL</span>
              </div>
            )}
          </div>
          <ProfileList
            isMobile={true}
            current={year}
            activeYear={activeYear}
            handleActiveYear={handleActiveYear}
            handleShowAll={handleShowAll}
          />
        </div>
      ))}
    </div>
  );
}

export default MobileProfileList;
