import React from "react";
import faqs from "../../../../common/data/faqs";

function Faq() {
  return (
    <section className="wit_section" data-section="faqs" id="faqs">
      <div
        className="wit-container faq-container wit_animate"
        data-wit-animate="animate__animated animate__fadeInUp"
      >
        <h1 className="text-center faq-heading my-5 py-3">FAQs</h1>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {faqs.map((faq, idx) => (
            <div className="accordion-item mb-3 faq-accordion" key={idx}>
              <div className="accordion-header" id={`flush-heading${idx}`}>
                <div
                  className="accordion-button wit-accordion-header collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target={`#faq${idx}`}
                  aria-expanded="false"
                  aria-controls={`faq${idx}`}
                >
                  <b>{faq.title}</b>
                </div>
              </div>
              <div
                id={`faq${idx}`}
                className="accordion-collapse collapse"
                aria-labelledby={`flush-heading${idx}`}
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  className="accordion-body"
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: faq.description }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="d-flex justify-content-center py-5 my-5 wit-container wit_animate"
        data-wit-animate="animate__animated animate__fadeInUp"
      >
        <div className="wit-secret py-5 d-flex justify-content-center">
          <div>
            <div className="secret-text-group">
              <span className="big-text">
                {" "}
                Let’s let you in on a little secret:
                <sub className="wit-small-text">
                  This website was designed and developed by two previous
                  women-in-tech interns, <b>Chioma &amp; Tefe</b>. If you’d love
                  to build cool stuff like this, then you should definitely
                  apply for the internship.
                </sub>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
