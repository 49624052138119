import React from "react";
import PageWrapper from "../../common/component/PageWrapper";
import HomeComponent from "./HomeComponent";

function Home() {

  return (
    <PageWrapper load={true}>
      <HomeComponent />
    </PageWrapper>
  );
}

export default Home;
